import { getContent } from '@/services/cms/util.js'
import sanitizeHtml from 'sanitize-html'

export function MMDDYYYY(date_string) {
	if (date_string == null) return 'invalid date'
	const dateify = new Date(date_string)
	const date = new Date(dateify.toISOString().slice(0, -1))

	let year = date.getFullYear()

	let month = (1 + date.getMonth()).toString()
	month = month.length > 1 ? month : '0' + month

	let day = date.getDate().toString()
	day = day.length > 1 ? day : '0' + day

	return month + '/' + day + '/' + year
}

export function YYYYMMDD(date_string) {
	if (date_string == null) return 'invalid date'
	const dateify = new Date(date_string)
	const date = new Date(dateify.toISOString().slice(0, -1))

	let year = date.getFullYear()

	let month = (1 + date.getMonth()).toString()
	month = month.length > 1 ? month : '0' + month

	let day = date.getDate().toString()
	day = day.length > 1 ? day : '0' + day

	return year + '-' + month + '-' + day
}

export function sortArrayByDate(array, dateKey, ascending = true) {
	return array.sort((a, b) => {
		const dateA = new Date(a[dateKey])
		const dateB = new Date(b[dateKey])
		if (ascending) {
			return dateA - dateB
		} else {
			return dateB - dateA
		}
	})
}

export function phoneNumberFormat(phoneNumberString) {
	const x = phoneNumberString.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
	phoneNumberString = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
	return phoneNumberString
}

export function toPascalCase(inputString) {
	if (!inputString) return inputString
	const words = inputString.trim().toLowerCase().split(' ')

	const pascalizedWords = words.map((word) => {
		return word.charAt(0).toUpperCase() + word.slice(1)
	})

	return pascalizedWords.join(' ')
}

export function concatFormatValidationErrors(errors) {
	let errorsString
	if (errors.length > 1) {
		errorsString = errors
			.map((e) => {
				return getContent(e.code)
			})
			.join(' ')
			.trim()
	} else {
		errorsString = getContent(errors[0].code)
	}
	return errorsString
}

export function openPdfInNewTab(content, pdfWindow = false) {
	const pdfData = encodeURI(content)

	if (pdfWindow === false) {
		pdfWindow = window.open('about:blank')
	}

	pdfWindow.document.write(
		"<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
			htmlEscape(pdfData) +
			"'></iframe>"
	)
}

function htmlEscape(str) {
	return String(str)
		.replace(/&/g, '&amp;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#39;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
}

export const session = {
	get(name) {
		return JSON.parse(sessionStorage.getItem(name || '{}'))
	},
	set(name, payload) {
		sessionStorage.setItem(name, JSON.stringify(payload))
	}
}

export const sanitize = (html) => {
	return sanitizeHtml(html, {
		allowedAttributes: {
			a: ['href', 'class'],
			p: ['class'],
			div: ['class'],
			span: ['class']
		}
	})
}

export const passwordRules = {
	currentPasswordValid(password) {
		return password.length >= 6
	},
	lengthRule(password) {
		return password.length >= 8
	},
	lowercaseRule(password) {
		return password.match('(?=.*[a-z])')
	},
	uppercaseRule(password) {
		return password.match('(?=.*[A-Z])')
	},
	numberRule(password) {
		return password.match('(?=.*[0-9])')
	},
	specialCharRule(password) {
		return (
			password.match(
				'(?=.*[-#!&@$%^*()_+=~`;:\'",.?</|\\]>[}{óÓôÔòÒñÑöÖ¡¿ÇçŒœßØøÅåÆæÞþÐð«»¢£¥€¤\\\\])'
			) &&
			!password.match(
				/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
			)
		)
	}
}

export function disableByRange(start, end) {
	const startDate = new Date(start)
	const endDate = new Date(end)
	const today = new Date()

	return today >= startDate && today <= endDate ? true : false
}
