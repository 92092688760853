<template>
	<v-dialog v-model="show">
		<v-card color="white decline-modal-container pa-2">
			<v-toolbar color="transparent">
				<template v-slot:append>
					<v-btn icon="mdi-close" @click.stop="executeCancelAction"></v-btn>
				</template>
			</v-toolbar>
			<v-card-title class="px-4 pt-4 pb-2">
				{{ confirmTitle }}
			</v-card-title>
			<v-card-text class="text-secondary px-4 pt-2 pb-6" v-html="sanitize(confirmMessage)">
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions class="justify-center my-4">
				<v-btn
					variant="outlined"
					color="#575757"
					class="secondary"
					@click.stop="executeConfirmAction"
				>
					{{ confirmActionText }}
				</v-btn>
				<v-btn id="continue" variant="tonal" class="primary" @click.stop="executeCancelAction">
					{{ cancelActionText }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import sanitizeHtml from 'sanitize-html'
export default {
	name: 'ConfirmModal',
	props: {
		confirmMessage: String,
		confirmTitle: String,
		confirmAction: Function,
		confirmActionText: String,
		cancelAction: {
			type: Function,
			default: () => 1
		},
		cancelActionText: {
			type: String,
			default: 'Cancel'
		},
		value: Boolean
	},
	computed: {
		show: {
			get() {
				return this.value
			},
			set(value) {
				this.cancelAction()
				this.$emit('update:modelValue', value)
			}
		}
	},
	methods: {
		executeConfirmAction() {
			this.confirmAction()
			this.show = false
		},
		executeCancelAction() {
			this.cancelAction()
			this.show = false
		},
		sanitize(html) {
			return sanitizeHtml(html, {
				allowedAttributes: {
					a: ['href', 'class', 'target'],
					p: ['class'],
					div: ['class'],
					ul: ['class']
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.v-card {
	// height: auto;
	max-width: 343px;
	margin: 0 auto;
	border-radius: 12px !important;
	background: no-repeat url(@/assets/logo-small-caution.svg);
}

.v-card-title {
	white-space: normal;
}

.v-card-actions {
	flex-direction: column;
	gap: 8px;

	.v-btn {
		width: 100%;
		margin-inline-start: 0 !important;

		.v-btn__content {
			color: $neutral-500;
		}

		&:last-child {
			border: 1px $primary-200;
		}
	}
}

:deep(.v-card-text) {
	p + p {
		margin-block-start: 1em;
	}
}

@media only screen and (min-width: 600px) {
	.v-card {
		max-width: 400px;

		.v-card-actions {
			flex-direction: row;
			gap: 8px;

			.v-btn {
				width: 170px;
				height: 44px !important;
			}
		}
	}
}
</style>
