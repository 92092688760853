import enUsJson from './content-en-us.json'

function compareDates(a, b) {
	const dateA = new Date(a.date)
	const dateB = new Date(b.date)
	return dateA - dateB
}

function getDateContent(date_logic) {
	const sortedDates = date_logic.sort(compareDates)
	let dataId = 'data'

	sortedDates.forEach((item) => {
		const today = new Date()
		const future = new Date(item.date)

		if (today - future >= 0) dataId = item.content
	})
	return dataId
}

export const getContentFull = (contentId, language = 'en-us', fromCms = false) => {
	if (!fromCms) {
		if (language !== 'en-us') return
		const result = enUsJson.filter(
			(json) => json.content_id.toLowerCase() === contentId.toLowerCase()
		)
		return result.length === 0 ? contentId : result[0]
	}
	return contentId
}

export const getContent = (contentId, language = 'en-us', fromCms = false) => {
	if (!fromCms) {
		if (language !== 'en-us') return
		const result = enUsJson.filter(
			(json) => json.content_id.toLowerCase() === contentId.toLowerCase()
		)
		if (result[0]?.date_logic) {
			return result.length === 0 ? contentId : result[0][getDateContent(result[0]?.date_logic)]
		} else {
			return result.length === 0 ? contentId : result[0]['data']
		}
	}
	return contentId
}
